<template>
  <div>
    <!-- 首图 -->
    <div class="bannerCover">
      <img src="@/assets/img/News/banner-news.png" />
    </div>
    <!-- tab切换 -->
    <div class="tabBox">
      <div
        :style="`${activeID == 0 ? 'color: #47B0B4;' : 'color: #B3B3B3;'}`"
        @click="handleSelectChange(0)"
      >
        全部资讯
      </div>
      <div
        :style="`${activeID == 1 ? 'color: #47B0B4;' : 'color: #B3B3B3;'}`"
        @click="handleSelectChange(1)"
      >
        公司资讯
      </div>
      <div
        :style="`${activeID == 2 ? 'color: #47B0B4;' : 'color: #B3B3B3;'}`"
        @click="handleSelectChange(2)"
      >
        行业资讯
      </div>
      <span
        :style="`left: ${activeID * (100 / 3)}%;`"
        class="tabBox_active"
      ></span>
    </div>
    <!-- 内容列表 -->
    <div>
      <van-swipe
        ref="vSwipe"
        :loop="false"
        duration="500"
        indicator-color="white"
        :initial-swipe="activeID"
        @change="handleSwitchChange"
      >
        <van-swipe-item v-for="(item, index) in NewsIndustryTotal" :key="index">
          <div class="contentBox">
            <div
              class="NewsIndustry_content"
              @click="goNewsDetails('/newsDetailsMobile', item)"
            >
              <div class="NewsIndustry_contentImg">
                <img :src="item.imgUrl" />
              </div>
              <div class="NewsIndustry_contentInfo">
                <div class="NewsIndustry_contentInfo_subtitle">
                  {{ item.title }}
                </div>
                <div class="NewsIndustry_contentInfo_content">
                  {{ item.info }}
                </div>
                <div class="NewsIndustry_contentInfo_time">
                  {{ item.time.split(" ")[0] }}
                </div>
              </div>
            </div>
            <div class="NewsIndustry_list">
              <div
                class="NewsIndustry_listItem"
                @click="goNewsDetails('/newsDetailsMobile', info)"
                v-for="(info, infoIndex) in item.NewsIndustryList"
                :key="infoIndex"
              >
                <div class="NewsIndustry_listItem_info">
                  <div class="NewsIndustry_listItem_infoTitle">
                    <span>·</span>{{ info.title }}
                  </div>
                  <div class="NewsIndustry_listItem_infoTime">
                    {{ info.time.split(" ")[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsIndustry_page">
              <el-pagination
                layout="prev, pager, next"
                :page-size="5"
                :pager-count="5"
                @current-change="(page) => handleCurrentChange(index, page)"
                :total="item.NewsIndustryLists.length"
              >
              </el-pagination>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { list, listOne, allList } from "@/api/list";
export default {
  data() {
    return {
      activeID: 0,
      // 行业资讯数据列表-总
      NewsIndustryTotal: [
        {
          imgUrl: require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png"),
        //   title: "2021年12月8日热烈祝贺广州微林股份有限公司成立！",
        //   time: "2021-12-08 09:00",
        //   content: `
        // <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal  style="text-align:left;" ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        // font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >热烈祝贺广州微林股份有限公司成立！</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;2021年12月8日广州微林股份有限公司宣告正式成立！</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><font face="宋体" >自</font>2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变,致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-align:left;" ><img width="303"  height="538"  src="${require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png")}" ><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(12,12,12);
        // letter-spacing:0.0000pt;text-transform:none;font-style:normal;
        // font-size:13.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        // `,
        //   info: "2021年12月8日广州微林股份有限公司宣告正式成立！自2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变, 致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。",
        //   NewsIndustryList: [],
        //   NewsIndustryLists: allList,
        },
        {
          imgUrl: require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png"),
        //   title: "2021年12月8日热烈祝贺广州微林股份有限公司成立！",
        //   time: "2021-12-08 09:00",
        //   content: `
        // <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal  style="text-align:left;" ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        // font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >热烈祝贺广州微林股份有限公司成立！</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;2021年12月8日广州微林股份有限公司宣告正式成立！</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><font face="宋体" >自</font>2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变,致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-align:left;" ><img width="303"  height="538"  src="${require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png")}" ><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(12,12,12);
        // letter-spacing:0.0000pt;text-transform:none;font-style:normal;
        // font-size:13.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        // `,
        //   info: "2021年12月8日广州微林股份有限公司宣告正式成立！自2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变, 致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。",
        //   NewsIndustryList: [],
        //   NewsIndustryLists: listOne,
        },
        {
          imgUrl: require("@/assets/img/News/20200104115018354.png"),
          title: "机器视觉在工业化生产上的运用",
          time: "2020-01-04 11:47",
          info: "设计方案到各式各样的检测，生产制造牢靠及零件鉴别的运用，在传统式的人工检测监控中，一般人的眼睛没法不断好的工作，别的物理学控制器也没办法充分发挥到功效。",
          content: `
          <div>
  <div class="div-middle">
      <div class="row">
          <div class="col-md-10">
              <span style="font-size:16px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;设计方案到各式各样的检测，生产制造牢靠及零件鉴别的运用，在传统式的人工检测监控中，一般人的眼睛没法不断好的工作，别的物理学控制器也没办法充分发挥到功效。</span><br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="${require("@/assets/img/News/20191127171706_8452.jpg")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;从而大家刚开始考虑到运用光学显像系统软件收集被控总体目标的图象，然后经电子计算机或技术专业的图象处理控制模块开展智能化解决，依据图象的清晰度遍布，色度和色调等信息内容，来开展规格、样子、色调等的辨别。那样，就可以电子计算机的高频率性、精确性，与人的眼睛視覺的高宽比智能化系统和抽象性工作能力相组成，从而造成了机器视觉的定义。視覺缺点检测设备</span><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;机器视觉在工业化生产上的运用：</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;1：外型缺点检测。检测生产流水线商品有没有产品质量问题，该阶段都是替代人工数最多的阶段。如剪刀制造行业中，电感器电容器的外型检测，包含有裂痕、崩缺、污渍、形变等欠佳缺点检测。</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;2：正确引导和精准定位。視覺精准定位规定机器视觉系统可以迅速精确的寻找被测零件并精确其部位，左右料应用机器视觉精准定位，正确引导器材胳膊精确爬取。</span><br />
<img src="${require("@/assets/img/News/20191121180039_4569.jpg")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;在半导体材料封裝行业，设备必须依据机器视觉获得的集成ic部位信息内容整拾去偷，精确捡取集成ic并开展关联，这就是说視覺精准定位在机器视觉工业生产行业最基础的运用。</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;3：鉴别，就是说运用机器视觉对图象开展解决、剖析和了解、以鉴别各种各样不一样方式的总体目标和目标。能够超过数据库的追朔和收集，在汽车零部件、食品类、药物等运用较多，如颜色检测，空格符有没有鉴别等。</span><br />
<img src="${require("@/assets/img/News/20191126185558_6725.png")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;机器视觉现阶段运用制造行业十分普遍，关键有药品、包裝、电子器件、汽车工业、半导体材料、纺织品、香烟、交通出行、货运物流等制造行业，用机器视觉技术性替代人工，能够合理提升生产率和产品品质。</span> 
              </div>
              <div class="col-md-2"></div>
          </div>
      </div>
  </div>
</div>
        `,
          // 展示行业资讯列表
          NewsIndustryList: [],
          // 行业资讯数据列表
          NewsIndustryLists: list,
        },
      ],
    };
  },
  created() {
    this.NewsIndustryTotal.map((item) => {
      item.NewsIndustryList = item.NewsIndustryLists.slice(0, 5);
    });
  },
  methods: {
    // 处理选中切换
    handleSelectChange(index) {
      this.$refs.vSwipe.swipeTo(index);
    },
    // 处理轮播切换
    handleSwitchChange(index) {
      this.$nextTick(() => {
        this.activeID = index;
      });
    },
    // 处理分页
    handleCurrentChange(index, page) {
      this.$nextTick(() => {
        this.NewsIndustryTotal[index].NewsIndustryList = [
          ...this.NewsIndustryTotal[index].NewsIndustryLists.slice(
            page * 5 - 5,
            page * 5
          ),
        ];
      });
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
  },
};
</script>

<style scoped>
/* 首页 */
.bannerCover {
  width: 100%;
  height: 136px;
}
.bannerCover img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 切换 */
.tabBox {
  font-weight: 500;
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  padding: 20px 0;
}
.tabBox_active {
  bottom: 0;
  width: calc(100% / 3);
  border-bottom: 2px solid #47b0b4;
  position: absolute;
  transition: all 0.5s;
}
.contentBox {
  width: 100%;
  padding: 40px 20px 0;
  box-sizing: border-box;
}
/* 行业资讯-内容 */
.NewsIndustry_content {
  width: 100%;
  padding: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}
.NewsIndustry_contentImg {
  width: 100%;
  height: 200px;
}
.NewsIndustry_contentImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.NewsIndustry_contentInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.NewsIndustry_contentInfo_subtitle {
  font-size: 18px;
  color: #333;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  overflow: hidden;
}
.NewsIndustry_contentInfo_content {
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
  padding-top: 10px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  border-top: 1px solid #c4c4c4;
}
.NewsIndustry_contentInfo_time {
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  margin-top: 10px;
  line-height: 18px;
}
/* 公司资讯-列表 */
.NewsIndustry_listItem {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.NewsIndustry_listItem_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NewsIndustry_listItem_infoTitle {
  font-size: 16px;
  width: 70%;
  color: #999;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.NewsIndustry_listItem_infoTitle span {
  margin-right: 10px;
}
.NewsIndustry_listItem_infoTime {
  font-size: 14px;
  font-weight: 400;
  color: #cccccc;
  line-height: 20px;
}
/* 公司资讯-分页 */
.NewsIndustry_page {
  width: 100%;
  padding: 0 20px;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.el-pagination button:disabled {
  background-color: #f5f5f5;
}
.el-dialog,
.el-pager li {
  background-color: #f5f5f5;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: #f5f5f5;
}
.NewsIndustry_page >>> .el-pager li.active {
  color: #47b0b4;
}
</style>